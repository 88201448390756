 jQuery(function(){
	/* Timeline */
	var timelineItems = $('.timeline-item'),
		visible = 0;
	
	$(window).on('scroll.timeline load.timeline', function () {
		timelineItems.each(function() {
			var item = $(this);
			if(inViewport(item) && !item.hasClass('timeline-item-visible')) {
				item.addClass('timeline-item-visible');
				visible++;
			}
			if(visible >= timelineItems.length) {
				$(window).off('scroll.timeline');
			}
		});
	});
 });