 jQuery(function(){
 	var counter = 	$('#counter');

 	if(counter.length > 0) {
 		var date = counter.data("date");
	 	counter.countdown(date, function(event) {
			$('.counter-days').html(event.strftime('%D'));
			$('.counter-hours').html(event.strftime('%H'));
			$('.counter-minutes').html(event.strftime('%M'));
			$('.counter-seconds').html(event.strftime('%S'));
		});
 	}
 });