var sliders = [];

$(document).ready(function ($) {

	// Sudo slider
	if ($.fn.sudoSlider) {

		// Teaser on homepage - slider
		$('.teaser-hp-slider').each(function (index) {
			var panelNum = $(this).find('.panel').length;

			if (panelNum > 1) {
				teaserSlider = $(this).find('.slider').sudoSlider({
					//effect: 'fade',
					vertical: false,
					auto: true,
					autowidth: false,
					continuous: true,
					prevNext: false,
					numeric: true,
					slideCount: 1,
					speed: 250,
					pause: 9000,
					responsive: true,
					controlsAttr: 'class="slider-nav"',
				});
			}
		});

		// Product gallery slider
		$('.product-slider').each(function (index) {
			var panelNum = $(this).find('.panel').length;

			if (panelNum > 1) {
				productSlider = $(this).find('.slider').sudoSlider({
					//effect: 'fade',
					vertical: false,
					auto: false,
					autowidth: false,
					continuous: true,
					prevNext: false,
					slideCount: 1,
					speed: 250,
					responsive: true,
					customLink: '.product-slider-link'
				});
			}
		});

		// Sliding in product gallery navigation
		$('.product-slider-nav').each(function (index) {
			var panelNum = $(this).find('.panel').length;

			if (panelNum > 4) {
				productSlider = $(this).find('.slider').sudoSlider({
					//effect: 'fade',
					vertical: true,
					auto: false,
					autowidth: false,
					continuous: false,
					prevNext: false,
					slideCount: 4,
					moveCount: 1,
					speed: 250,
					responsive: true,
					customLink: '.product-slider-nav-link'
				});
			}
		});

		// Item slider
		$('.item-slider').each(function (index) {
			var id = $(this).attr('id');
			sliders[id] = [];
			var el = $(this);

			el.attr('data-slides') ? sliders[id]['slideNum'] = el.attr('data-slides') : sliders[id]['slideNum'] = 1;
			el.attr('data-slides-desktop') ? sliders[id]['slideNumDesktop'] = el.attr('data-slides-desktop') : sliders[id]['slideNumDesktop'] = sliders[id]['slideNum'];
			el.attr('data-slides-tablet') ? sliders[id]['slideNumTablet'] = el.attr('data-slides-tablet') : sliders[id]['slideNumTablet'] = sliders[id]['slideNumDesktop'];
			el.attr('data-slides-mobile') ? sliders[id]['slideNumMobile'] = el.attr('data-slides-mobile') : sliders[id]['slideNumMobile'] = sliders[id]['slideNumTablet'];
			el.attr('data-auto') ? sliders[id]['slideAuto'] = el.data('auto') : sliders[id]['slideAuto'] = true;
			el.attr('data-nav') ? sliders[id]['slideNav'] = el.data('nav') : sliders[id]['slideNav'] = true;
			el.attr('data-numeric') ? sliders[id]['slideNumeric'] = el.data('numeric') : sliders[id]['slideNumeric'] = true;
			el.attr('data-continuous') ? sliders[id]['slideContinuous'] = el.data('continuous') : sliders[id]['slideContinuous'] = true;
			el.attr('data-speed') ? sliders[id]['slideSpeed'] = el.data('speed') : sliders[id]['slideSpeed'] = 250;
			el.attr('data-effect') ? sliders[id]['slideEffect'] = el.data('effect') : sliders[id]['slideEffect'] = 'fade';

			var panelNum = el.find('.panel').length;

			if (panelNum > sliders[id]['slideNum']) {
				sliders[id]['slider'] = el.find('.slider').sudoSlider({
					effect: sliders[id]['slideEffect'],
					vertical: false,
					auto: sliders[id]['slideAuto'],
					autowidth: false,
					continuous: sliders[id]['slideContinuous'],
					prevNext: sliders[id]['slideNav'],
					numeric: sliders[id]['slideNumeric'],
					slideCount: sliders[id]['slideNum'],
					speed: sliders[id]['slideSpeed'],
					responsive: true,
					customLink: 'a.customLink',
					controlsAttr: 'class="slider-nav"',
					prevHtml: '<a href="#" class="prev"></a>',
					nextHtml: '<a href="#" class="next"></a>'
				});
			}
		});

		// References slider - NEW
		$('.slider-references').each(function (index) {
			var panelNum = $(this).find('.panel').length;
			var id = 'references-' + index;
			sliders[id] = [];

			// Set default options
			sliders[id]['slideNum'] = 2; // Show 2 slides on desktop
			sliders[id]['slideNumMobile'] = 1; // Show 1 slide on mobile
			sliders[id]['slideAuto'] = true;
			sliders[id]['slideSpeed'] = 500;
			sliders[id]['slideContinuous'] = true;
			sliders[id]['slideNav'] = true;
			sliders[id]['slideNav'] = true;

			if (panelNum > 1) {
				sliders[id]['slider'] = $(this).find('.slider').sudoSlider({
					effect: 'slide',
					vertical: false,
					auto: sliders[id]['slideAuto'],
					autowidth: false,
					continuous: sliders[id]['slideContinuous'],
					prevNext: sliders[id]['slideNav'],
					numeric: false,
					responsive: true,
					slideCount: sliders[id]['slideNum'],
					speed: sliders[id]['slideSpeed'],
					pause: 9000,
					beforeAnimation: function(slide) {
						// Handle responsive behavior
						var width = $(window).width();

						if (width <= 768) { // mobile breakpoint
                            sliders[id]['slider'].setOption("slideCount", sliders[id]['slideNumMobile']);
						} else {
                            sliders[id]['slider'].setOption("slideCount", sliders[id]['slideNum']);
						}
					},
					controlsAttr: 'class="slider-references-buttons" style="position: absolute; top: 0; right: 0;"',
					prevHtml: '<a href="javascript:void(0);" class="reference-slider-nav-link reference-slider-nav-prev"\n' +
                        '\t\t\t\t\t\t   data-target="prev">\n' +
                        '\t\t\t\t\t\t\t<svg width="24" height="24" viewBox="0 0 24 24" fill="none"\n' +
                        '\t\t\t\t\t\t\t     xmlns="http://www.w3.org/2000/svg">\n' +
                        '\t\t\t\t\t\t\t\t<path d="M15 4L7 12L15 20" stroke="#9C1F00" stroke-width="2" stroke-linecap="round"\n' +
                        '\t\t\t\t\t\t\t\t      stroke-linejoin="round"/>\n' +
                        '\t\t\t\t\t\t\t</svg>\n' +
                        '\t\t\t\t\t\t</a>',
					nextHtml: '<a href="javascript:void(0);" class="reference-slider-nav-link reference-slider-nav-next"\n' +
                        '\t\t\t\t\t\t   data-target="next">\n' +
                        '\t\t\t\t\t\t\t<svg width="24" height="24" viewBox="0 0 24 24" fill="none"\n' +
                        '\t\t\t\t\t\t\t     xmlns="http://www.w3.org/2000/svg">\n' +
                        '\t\t\t\t\t\t\t\t<path d="M9 20L17 12L9 4" stroke="#9C1F00" stroke-width="2" stroke-linecap="round"\n' +
                        '\t\t\t\t\t\t\t\t      stroke-linejoin="round"/>\n' +
                        '\t\t\t\t\t\t\t</svg>\n' +
                        '\t\t\t\t\t\t</a>'
				});
			}
		});

		/*$(window).on("resizeEnd focus", function () {
			for (var i in sliders) {
				if (!sliders.hasOwnProperty(i))
					continue;

				var width = $(window).width();

				var orgSlideCount = sliders[i]['slider'].getOption("slideCount");
				var slideCount;

				if (width >= 1200) {
					slideCount = sliders[i]['slideNum']; // 1200 - 1920
				}
				else if (width >= 992) {
					slideCount = sliders[i]['slideNumDesktop']; // 992 - 1199
				}
				else if (width >= 768) {
					slideCount = sliders[i]['slideNumTablet']; // 768 - 991
				}
				else {
					slideCount = sliders[i]['slideNumMobile']; // 0 - 767
				}
				if (slideCount != orgSlideCount) {
					sliders[i]['slider'].setOption("slideCount", slideCount);
				}
			};
		}).resize();*/
	}


});
