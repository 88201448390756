 jQuery(function(){
	var map = $('.map-wrap');
	
	if(map.length > 0) {
		var titles = map.find('.map-title');
		titles.each(function() {
			var title = $(this),
				regionSel = '#' + $(this).data('path'),
				region = $(regionSel);
			if(region.length > 0) {
				var offset = region.offset();
				var regionWidth = region[0].getBoundingClientRect().width;
				offset.left = offset.left + regionWidth/2 - 20;
				offset.top = offset.top - 30;
				title.offset(offset);

				$(regionSel).on('mouseover' , function() {
					title.addClass('map-title-visible');
					region.attr("class", "map-region map-region-hovered");
				});
				$(regionSel).on('mouseleave' , function() {
					title.removeClass('map-title-visible');
					region.attr("class", "map-region");
				});
			}
		});

		$('#Praha, #praha-mesto').on('mouseover', function() {
			$('#praha-mesto').addClass('map-region-city-hovered');
			$('#Praha').attr("class", 'map-region map-region-hovered');
		});
		$('#Praha, #praha-mesto').on('mouseleave', function() {
			$('#praha-mesto').removeClass('map-region-city-hovered');
			$('#Praha').attr("class", 'map-region');
		});
	}
	
	/*var refList = $('#seznam-referenci');
	if(refList.length > 0) {
		var destination = refList.offset().top;
		$("html:not(:animated),body:not(:animated)").animate(
			{ scrollTop: destination },
			500
		);
	}*/
 });