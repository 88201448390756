 jQuery(function(){
	var body = $('body'),
		dropdownTriggers = $('.dropdown-trigger-click'),
		menuTrigger = $('.menu-trigger');

	menuTrigger.on('click', function(e) {
		e.preventDefault();
		body.toggleClass('menu-open');
	});

	dropdownTriggers.each(function() {
		var trigger = $(this),
			elId = trigger.data('dropdown'),
			dropdown = $('#' + elId);
		if(dropdown.length) {
			trigger.on('click', function(e) {
				if($(window).width() < 992) {
					e.preventDefault();
					e.stopPropagation();
					dropdown.slideToggle('fast');
					trigger.toggleClass('active');
				}
			});
		}
	});
 });